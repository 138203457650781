import React from "react"
import BodyLayout from "../components/bodyLayout"
import { Col, Row, Button, Icon } from "antd"
import Img from "gatsby-image"
import SEO from '../components/SEO'


export default ({data}) => (
  <BodyLayout color={'rgb(255, 111, 97)'}>
  <SEO title={'Statiste - Guide investissement'} description={"Le guide de l'investissement rationnel"} />
  <div>
  <Row style={{paddingTop:30}}>
  <Col xs={{span:22,offset:1}} md={{span:20,offset:2}}>
    <Row type="flex" align="middle" left="space-between">
      <Col span={8}>
      <Img fluid={data.profile.childImageSharp.fluid} />
      </Col>
      <Col span={14} offset={1} align='left'>
      <h1 id="gir" style={{border: '3px dashed'}}>Le guide de l'investissement rationnel</h1>
      </Col>
    </Row>
    <Row>
    <Col md={{span:8}} xs={{span:24, offset:0}}>
    <Button className="button-gir" block={true} type="dashed"><a href={data.allFile.edges[0].node.publicURL} download>Télécharger mon exemplaire</a></Button>
    </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={24} offset={0} align="left">
      <h3>Pour qui ?</h3>
      <p>
      <span id="gir">Tous ceux qui gèrent des budgets</span> qui ont un impact sur les ventes
      ou la considération d'un produit en vente et plus généralement sur le chiffre d'affaire d'une entreprise.
      </p>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={24} offset={0} align="left">
      <h3>Pour quoi ?</h3>
      <p>
      L'allocation d'un budget ou la façon dont on doit le dépenser sont des décisions
      trop souvent prises sans considérations scientifiques. Le petit guide de l'investissement
      apporte des <span id='gir'>réponses précises et spécifiques basées sur des
      analyses statistiques simples et interprétables</span>.
      </p>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={24} offset={0} align="left">
      <h3>Pour quand ?</h3>
      <p>
      <span id='gir'>En amont de toute manœuvre impliquant un investissement</span>. Marketing, logistique,
      lancement d’un nouveau produit, ouverture d’un magasin, changement de prix,
      promotions, mise en place d’une campagne média.
      </p>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={24} offset={0} align="left">
      <h3>Comment ?</h3>
      <p>
      Grâce à l'économétrie, chaque facteur qui influence votre business est pris à part indépendamment des autres.
      </p>
      <p>
      Plus d'infos en téléchargeant le guide de l'investissement rationnel en exemple.
      </p>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={23} offset={0} align="left">
      <h4>Quelques questions:</h4>
      <p><Icon type="right"/>Comment connaitre l'impact de mes actions sur mon business ? sur mon trafic web ?</p>
      <p><Icon type="right"/>Mes promotions ont-elles été trop ou pas assez agressives ?</p>
      <p><Icon type="right"/>Quels mots-clés dois-je prioriser sur Google Ads ?</p>
      <p><Icon type="right"/>Quels influenceurs m'ont apporté le plus de visibilité ?</p>
      <p><Icon type="right"/>Combien puis-je espérer gagner au lancement de ce nouveau produit ? partenariat ?</p>
      <p><Icon type="right"/>A chaque business, ses spécificités et ses questions</p>
      </Col>
    </Row>
  </Col>
  </Row>
  </div>
  </BodyLayout>
)

export const query = graphql`
  {
    profile: file(relativePath: {eq:"img/book.jpg"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1000){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allFile(filter: {extension: {eq: "pdf"}, relativePath: {eq: "pdf/DQPM_guide_investissement_rationnel.pdf"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
  `
